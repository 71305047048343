import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
import hvhh1 from '../../static/img/work/hvhh-trailer/hvhh-1.png';
import hvhh2 from '../../static/img/work/hvhh-trailer/hvhh-2.png';
import hvhh3 from '../../static/img/work/hvhh-trailer/hvhh-3.png';
import hvhh4 from '../../static/img/work/hvhh-trailer/hvhh-4.png';
export const _frontmatter = {
  "key": 7,
  "title": "Trailer Houten Vloeren Houten Hart",
  "client": null,
  "type": "audiovisual",
  "date": "2017-2019",
  "description": "Trailer for a podcast that I made with Rient van de Crommert and Roy Jakobs. Is is about the stark contrast withing De Nieuwe Binnenweg, a big street in Rotterdam, with on one side the trendy shops and on the other hand the old stuffy shops that have been there forever.",
  "image": "../../static/img/work/hvhh-trailer/hvhh-1.png",
  "video": "https://player.vimeo.com/video/247296504",
  "alt": "boy looking",
  "runningTime": "8:54",
  "rating": 2
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
    <img src={hvhh3} alt="piece of the film" />
    <img src={hvhh4} alt="piece of the film" />
    <img src={hvhh1} alt="piece of the film" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      